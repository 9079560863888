import React, { Fragment } from "react";
import { render } from "react-dom";
import { QuestionGroup } from "react-multiple-choice";
import { Question } from "react-multiple-choice";
import { Option } from "react-multiple-choice";
import { MultipleQuestions } from "react-multiple-choice";
import { Test } from "react-multiple-choice";

class Exam extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      selectedOptions: {},
      title : this.props.questionTitle,
      questions: this.props.choices,
      correct_index: this.props.trueChoice,
      selectedIndex:-1,
      isClicked: false,
      loading: true
    };
  }
  

  componentDidMount() {
    
  }


  updateState(i) {

    localStorage.setItem("pointerdone",1)
    this.setState({
      isClicked:true,
      selectedIndex:i,
    
    });
    // Changing state
   
}
  render() {
    if(this.props.questionTitle != this.state.title){
      this.setState({
        title:this.props.questionTitle,
        isClicked:false,
        selectedIndex:-1,
        correct_index:this.props.trueChoice,
      })
    }
    return(
      <div className="newdiv">
      <h3 className="question-title">{this.props.questionTitle}</h3>
   
        <ul className="exam-style-head">
          {
          this.props.choices.map((item, i)=>{
            //console.log('test');
            return (
              <li 
              onClick={this.state.isClicked==false?()=>this.updateState(i)
            :null
            }
                className="exam-style-body"
                style={{
                  backgroundColor: (this.state.isClicked&& this.state.selectedIndex==i ) && (this.props.trueChoice == i? 'green' : this.props.questionType == 1 ?"green":'red'),
                  color: (this.state.isClicked&& this.state.selectedIndex==i)? 'white' : 'black'
                }}
                key={i}>
                  {item}
              </li>
              );
            })
          }
        </ul>
        {this.state.isClicked && (this.props.questionType == 0) &&
          <div
            className="answer-box">
            <h5>پاسخ:</h5>
            <p>
              {this.props.answer}
            </p>
          </div>
        }
      </div>
    )
  }
}
export default Exam;