import React, { useEffect, useState } from "react";
import { Route, useParams, useRouteMatch } from "react-router-dom";
//import { readDeck } from "../../utils/api/index";

import DeckScreenBreadcrumbNavBar from "./DeckScreenBreadcrumbNavBar";
import DeckInfo from "./DeckInfo";

function DeckScreen() {
  const [deck, setDeck] = useState({});
  const [cards, setCards] = useState([]);

  const deckId = useParams().deckId;
  const { url } = useRouteMatch();

  // loading the specified deck from the API
  useEffect(() => {
    async function loadDeck() {
      //const response = readDeck(deckId);
      //const deckFromAPI = await response;
      
      let deckiFromAPI = require('../../data/db.json').decks[deckId-1];
      //console.log(deckiFromAPI);
      let all_cards = require('../../data/db.json').cards;
      let cards = [];
      for(let i =0;i<all_cards.length;i++){
        if(all_cards[i].deckId == deckId)
          cards.push(all_cards[i]);
      }
      deckiFromAPI ={
        description:deckiFromAPI.description,
        id:deckiFromAPI.id,
        name:deckiFromAPI.name,
        cards:cards,
      }
      //console.log(deckiFromAPI);
      //setDeck(deckiFromAPI);
      setDeck(deckiFromAPI);
      setCards(deckiFromAPI.cards);
    }
    loadDeck();
  }, [deckId]);

  // If the deck has been fetched from the API, the breadcrumb nav bar and deck info will display
  if (deck.name) {
    return (
      <div>
        <DeckScreenBreadcrumbNavBar deckName={deck.name} />
        <Route path={url}>
          <DeckInfo
            deckName={deck.name}
            deckDescription={deck.description}
            deckId={deckId}
            cards={cards}
            url={url}
          />
        </Route>
      </div>
    );
  }
  return "Loading...";
}

export default DeckScreen;
