import React, { useEffect, useState } from "react";
//import { listDecks } from "../../utils/api/index";
import "./Home.css";
import './CardDesign.css';
import active from './Images/member.png'
import young from './Images/young.png'
import ill from './Images/ill.png'
import logo from './Images/logo 2.png';
import hand from './Images/hand.png';
import hand2 from './Images/hand2.png';
import CreateDeckButton from "./CreateDeckButton";
import ViewDeckButton from "./ViewDeckButton";
import StudyDeckButton from "./StudyDeckButton";
import DeleteDeckButton from "./DeleteDeckButton";
import { useHistory } from "react-router-dom";
function Home() {
  const [decks, setDecks] = useState([]);
  const history = useHistory();
  // Loading all of the decks from the API
  useEffect(() => {
    async function loadDecks() {
      //const response = listDecks();
      //const decksFromAPI = await response;
      
      //console.log(decksFromAPI);
      let deckiFromAPI = require('../../data/db.json').decks;
      //console.log(deckiFromAPI);
      setDecks(deckiFromAPI);
    }
    loadDecks();
  }, []);

  return (
    <div className="home">
      {/* <CreateDeckButton /> */}

      <img src={logo} className="logonew" height='100px' />
      <div>
        <p className="logi">
          دندان‌هایت را دوست داشته باش
        </p>
      </div>
      <div className="margbot">

        <div className="underlinesty"></div>

      </div>
      <div className="newhandi">
        <div className="handpoint">
          <img src={hand}  height='30px' />
          <p >
            آموزش بهداشت جهت ارتقا سلامت دهان و دندان
          </p>
          <img src={hand2}  height='30px' />
        </div>
      </div>
      {/* Creating a Bootstrap card for each deck and the associated buttons */}
      <div>
            <div className='mainCardContainer'>
                <div style={{background:"#c345fd"}} className='cardContainer'
                onClick={() => history.push(`/decks/${decks[0].id}/study`)}>
                    <div>
                        <img src={active} className="imagemarj" height='60px' />
                  
                        <div className='caredName'>{'کودکان زیر 6 سال'}</div>
                        <div className='circleOne'></div>
                        <div className='circleTwo'></div>
                        
                    </div>
                </div>
                
                <div style={{background:"#2fc5ff"}} className='cardContainer'
                onClick={() => history.push(`/decks/${decks[1].id}/study`)}>
                <div>
                  <img src={young} className="imagemarj" height='60px' />
                    <div className='caredName'>{'کودکان 6 تا 12 ساله'}</div>
                    
                    <div className='circleOne'></div>
                    <div className='circleTwo'></div>
                    

                </div>
                </div>
                <div style={{background:"#04f6cd"}} className='cardContainer'
                onClick={() => history.push(`/decks/${decks[2].id}/study`)}>
                    <div>
                        <img src={ill} className="imagemarj" height='60px'/>
                        <div className='caredName'>{'بیماران خاص'}</div>
                        
                        <div className='circleOne'></div>
                        <div className='circleTwo'></div>
                        

                    </div>
                </div>
            </div>

        </div>

    </div>
  );
}

export default Home;
