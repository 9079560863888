import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
//import { readDeck } from "../../utils/api/index";
import StudyScreenBreadcrumbNavBar from "./StudyScreenBreadcrumbNavBar";
import StudyCard from "./StudyCard";

function Study() {
  const [deck, setDeck] = useState({});
  const [cards, setCards] = useState([]);
  const [currentCard, setCurrentCard] = useState({});
  const deckId = useParams().deckId;
if(  localStorage.getItem('pointer') === null){
  localStorage.setItem('pointer', 0);
  localStorage.setItem('pointerdone', 0);
}
  // Loading the specified deck from the API
  useEffect(() => {
    async function loadDeck() {
      // const response = readDeck(deckId);
      // const deckFromAPI = await response;





      let deckiFromAPI = require('../../data/db.json').decks[deckId-1];
      //console.log(deckiFromAPI);
      let all_cards = require('../../data/db.json').cards;
      let cards = [];
      for(let i =0;i<all_cards.length;i++){
        if(all_cards[i].deckId == deckId)
          cards.push(all_cards[i]);
      }
      deckiFromAPI ={
        description:deckiFromAPI.description,
        id:deckiFromAPI.id,
        name:deckiFromAPI.name,
        cards:cards,
      }



      setDeck(deckiFromAPI);
      setCards(deckiFromAPI.cards);
      setCurrentCard(deckiFromAPI.cards[localStorage.getItem("pointer")]);
    }
    loadDeck();
  }, [deckId]);

  return (
    <div>
      <StudyScreenBreadcrumbNavBar deckId={deckId} deck={deck}/>
      {/* <h2 className="mb-4">یادگیری: {deck.name}</h2> */}
      <StudyCard cards={cards} currentCard={currentCard} setCurrentCard={setCurrentCard} deckId={deckId}/>
    </div>
  );
}

export default Study;
