import React, { useState } from "react";
import { useHistory, useRouteMatch } from "react-router";

import FlipButton from "./FlipButton";
import NextButton from "./NextButton";
import AddCardsButton from "./AddCardsButton";
import ReactHtmlParser from 'react-html-parser';
import Exam from './Exam';

function StudyCard({ cards, currentCard, setCurrentCard, deckId }) {
  const [cardCount, setCardCount] = useState(1);
  const [isFrontOfCard, setIsFrontOfCard] = useState(true);

  const history = useHistory();
  const { url } = useRouteMatch();

  // Function to handle clicks of the Next button
  const NextCardHandler = () => {
    // Keeping track of which card in the deck the user is currently viewing
    if (localStorage.getItem('pointer') < cards.length) {
      if(currentCard.type == 1){
        if(localStorage.getItem('pointerdone') == 0){
          window.alert("لطفا یکی از گزینه‌های سوال زیر را انتخاب نمایید")
        }else{
          localStorage.setItem('pointerdone',0);
          localStorage.setItem('pointer', parseInt(localStorage.getItem('pointer')) + 1);
          let pint = localStorage.getItem('pointer');
          if(parseInt(localStorage.getItem('pointer'))>= cards.length){
            localStorage.setItem('pointer', 0);
          }
          setIsFrontOfCard((currentSide) => !currentSide);
          setCurrentCard(cards[pint]);
          setCardCount((currentCount) => currentCount + 1);
          
        }
      }else {
        localStorage.setItem('pointerdone',0);
        localStorage.setItem('pointer', parseInt(localStorage.getItem('pointer')) + 1);
        let pint = localStorage.getItem('pointer');
          if(parseInt(localStorage.getItem('pointer'))>= cards.length){
            localStorage.setItem('pointer', 0);
          }
        setIsFrontOfCard((currentSide) => !currentSide);
        setCurrentCard(cards[pint]);
        setCardCount((currentCount) => currentCount + 1);
        
      }
    } else {
      // Once the user has reached the final card in the deck, they will be prompted to either restart
      // the deck of cards, or return to the home page
      localStorage.setItem('pointer',0);
      localStorage.setItem('pointerdone',0);
      if (
        window.confirm(
          "آیا میخواهید اسلایدها را مجدد بررسی نمایید؟"
        )
      ) {
        
        setIsFrontOfCard((currentSide) => !currentSide);
        setCurrentCard(cards[0]);
        setCardCount(1);
        history.push(url);
      } else {
        history.push("/");
      }
    }
  };
const finish =() =>{
  localStorage.setItem('pointer', 0);
  localStorage.setItem('pointerdone',0);
  history.push("/");
}
  // If there are less than 3 cards in a deck, the user will be prompted to add cards to the deck
  if (cards.length < 3) {
    return (
      <div>
        <h4 className="text-danger font-weight-bold">  خطا</h4>
        <div className="card-text">
          <p>
          محتوی این بخش هنوز تکمیل نشده است.
          </p>
        </div>
        {/* <AddCardsButton deckId={deckId} /> */}
      </div>
    );
  }

//console.log(cards.length)
  
  if(currentCard.type==1){
    return(
      <div>
        <Exam 
          questionNumber={currentCard.questionNumber} 
          questionTitle = {
            currentCard.questionTitle
          }
          questionType={currentCard.questionType}
          trueChoice = {currentCard.trueChoice}
          answer ={currentCard.answer_title}
          choices ={currentCard.choices}
          />
        {cards.length -1 > parseInt(localStorage.getItem('pointer')) ?
            <NextButton NextCardHandler={NextCardHandler} />
            :<button type="button" className="btn btn-success btni" onClick={finish}>
            اتمام آموزش
          </button>
            }
      </div>
    );
  }
  else{
    if (currentCard.back != "") {
      if(currentCard.down == 0)
        return (
          <div className="deck-card card">
            <div className="card-body">
              <h5 className="card-title cardi">
                اسلاید {localStorage.getItem("pointer")} از {cards.length}
              </h5>
              <div className="card-text newcardi3">
                <div className="card-text newcardi1">{ReactHtmlParser(currentCard.front)}</div>
                <div className="newcardi2">
                    <img className="imaga" src={currentCard.back}  height='300px'/>
                  </div>
                </div>
                {cards.length -1 > parseInt(localStorage.getItem('pointer')) ?
            <NextButton NextCardHandler={NextCardHandler} />
            :<button type="button" className="btn btn-success btni" onClick={finish}>
            اتمام آموزش
          </button>
            }
            </div>
          </div>
        );
      else{
        return (
          <div className="deck-card card">
            <div className="card-body">
              <h5 className="card-title cardi">
                اسلاید {localStorage.getItem("pointer")} از {cards.length}
              </h5>
              <div className="card-text newcardi33">
                <div className="card-text newcardi11">{ReactHtmlParser(currentCard.front)}</div>
                <div className="newcardi22">
                    <img className="imaga" src={currentCard.back}  height='300px'/>
                  </div>
                </div>
                {cards.length -1> parseInt(localStorage.getItem('pointer')) ?
            <NextButton NextCardHandler={NextCardHandler} />
            :<button type="button" className="btn btn-success btni" onClick={finish}>
            اتمام آموزش
          </button>
            }
            </div>
          </div>
        );
      }
    }
    else {
      return (
        <div className="deck-card card">
          <div className="card-body">
            <h5 className="card-title cardi">
              اسلاید {localStorage.getItem("pointer")} از {cards.length}
            </h5>
            <div className="card-text">{ReactHtmlParser(currentCard.front)}</div>
            {cards.length -1 > localStorage.getItem('pointer') ?
            <NextButton NextCardHandler={NextCardHandler} />
            :<button type="button" className="btn btn-success btni" onClick={finish}>
            اتمام آموزش
          </button>
            }
          </div>
        </div>
      );
    }
  }



  // Renders the back of the card and the "Flip" and "Next" buttons if isFrontOfCard is false
  // return (
  //   <div className="deck-card card">
  //     <div className="card-body">
  //       <h5 className="card-title">
  //         Card {cardCount} of {cards.length}
  //       </h5>
  //       <p className="font-weight-bold font-italic mb-0">Back:</p>
  //       <p className="card-text">{currentCard.back}</p>
  //       <FlipButton setIsFrontOfCard={setIsFrontOfCard} />
  //       <NextButton NextCardHandler={NextCardHandler} />
  //     </div>
  //   </div>
  // );
}

export default StudyCard;
